/* Applied when the component is entering */
.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

/* Applied after the component starts entering */
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Applied when the component is exiting */
.fade-exit {
  opacity: 1;
  transform: translateX(0);
}

/* Applied after the component starts exiting */
.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
