.horizontal {
  height: 2px;
  background-color: #ffffff;
}

.horizontal.transparent {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.chosen {
  color: black;
  background: #fff;
  background: color(display-p3 1 1 1);
}

.perfume_women {
  width: 13vw; /* Equivalent to w-40 in Tailwind */
  position: absolute;
  bottom: 43%;
  left: 24%;
}

.perfume_women_2 {
  width: 13vw;
  position: absolute;
  bottom: 15%;
  left: 47%;
}
