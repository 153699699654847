.image-special {
  top: 30%;
  left: 50%;
}

.image-fixed-size {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .image-fixed-size {
    max-height: 150px;
  }
}
