/* MENU */
.menu_icon {
  width: 30px;
}

@media screen and (max-width: 768px) {
  .menu_icon {
    width: 25px;
  }
}

hr {
  height: 1px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.no-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
