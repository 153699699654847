.specials_card_2 {
  background: radial-gradient(
    90.55% 78.14% at 49.25% 52.53%,
    #22252c 0%,
    #000 100%
  );
  background: radial-gradient(
    90.55% 78.14% at 49.25% 52.53%,
    color(display-p3 0.1352 0.1452 0.1708) 0%,
    color(display-p3 0 0 0) 100%
  );
}

.specials_card_3 {
  background: radial-gradient(
    81.6% 81.6% at 52.08% 54.53%,
    #f2ab00 0%,
    #9c683c 100%
  );
}

.specials_card_4 {
  background-color: #e8ceb6;
}

.specials_card_5 {
  background: #9c87b8;
}
.specials_card_6 {
  background: linear-gradient(154.75deg, #ff95b1 16.02%, #a1d86c 72.71%);
}

.specials_card_1 {
  background: radial-gradient(
    81.6% 81.6% at 52.08% 54.53%,
    #6b3f18 0%,
    #9c683c 100%
  );
}

.horizontal {
  height: 2px;
  background-color: #ffffff;
}

.horizontal.transparent {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.chosen {
  color: black;
  background: #fff;
  background: color(display-p3 1 1 1);
}

.perfume {
  width: 10vw;
  position: absolute;
  bottom: 40%;
  left: 30%;
}

@media (max-width: 500px) {
  .perfume {
    width: 13vw;
  }
}

.perfume_2 {
  width: 13vw;
  position: absolute;
  bottom: 49%;
  left: 58%;
}

@media (max-width: 500px) {
  .perfume_2 {
    width: 16vw;
    left: 60%;
  }
}

@media (min-width: 500px) {
  .custom-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .custom-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .custom-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
