.body {
  background-image: radial-gradient(
    89.63% 59.76% at 48.67% 46%,
    color(display-p3 0.1922 0.1922 0.1922) 0%,
    color(display-p3 0 0 0) 100%
  );
  background-size: cover; /* Ensures the gradient covers the entire element */
  background-position: center; /* Centers the background */
  background-repeat: no-repeat; /* Prevents the gradient from repeating */
  width: 100%; /* Full width */
}

.package_chooser {
  border-radius: 13.965px;
  border: 0.635px solid #fff;
  border: 0.635px solid color(display-p3 1 1 1);
  background: rgba(255, 255, 255, 0.08);
  background: color(display-p3 1 1 1 / 0.08);
  backdrop-filter: blur(12.695884704589844px);
}

.clicked {
  border-radius: 13.965px;
  opacity: 0.7;
  background: #fff;
  color: black;
  background: color(display-p3 1 1 1);
}

.card {
  border-radius: 5px;
  border: 1px solid #fff;
  border: 1px solid color(display-p3 1 1 1);
  background: rgba(255, 255, 255, 0.08);
  background: color(display-p3 1 1 1 / 0.08);
  backdrop-filter: blur(20px);
}

.subscribe {
  background: #fff;
  background: color(display-p3 1 1 1);
  color: black;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
    0 0 30px rgba(255, 255, 255, 0.4), 0 0 60px rgba(255, 255, 255, 0.3);
}

.glow {
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
    0 0 30px rgba(255, 255, 255, 0.4), 0 0 60px rgba(255, 255, 255, 0.3);
}

.learn_now {
  color: #000;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 20.263px */
  letter-spacing: 0.507px;
}

/* Heading animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}
