@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Playfair Display", serif;
}

.border {
  border: 1px solid white;
}

.border_2 {
  border: 0.5px solid rgba(217, 217, 217, 0.75);
}
/* Skeleton Loader */
.skeleton-card {
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 16px;
  background-color: #454545;
  border-radius: 8px;
}
.skeleton-img {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #595959 25%, #a0a0a0 50%, #595959 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}
.skeleton-text {
  height: 20px;
  margin-top: 16px;
  background: linear-gradient(90deg, #595959 25%, #a0a0a0 50%, #595959 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}
.skeleton-text.short {
  width: 60%;
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.bg {
  background-color: #e43d3d;
}
