.vertical_line {
  width: 1px;
  height: auto;
  background-color: #ffffff;
  margin: 0 20px;
}

.horizontal_line {
  width: 41.369px;
  height: 0.5px;
}

.vertical_line.transparent {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
