




.sub_horizontal{
    height: 0.0625rem;
}

#deliveryAddress input , select{
    overflow: hidden;
    background: transparent;
    padding: 1.5%;
    outline: none;
    border:0.5px solid rgba(217, 217, 217, 0.75)
}

#deliveryAddress option {
    color: black;
}

#payment input{
    background: transparent;
    padding: 1.5%;
    outline: none;
    border:0.5px solid rgba(217, 217, 217, 0.75)
}